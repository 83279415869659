<template>
    <PageSection
        :title="$t('explore-header', {ns: 'educatordashboard'})"
        :description="$t('explore-description', { ns: 'educatordashboard'})"
        :viewAll="{ show: true, text: $t('explore-view-all', { ns: 'educatordashboard'}), link: '/connectors' }"
    >
        <div class="quick-filter-section__group">
            <NebulaButton
                v-for="(filter, idx) in filters"
                class="quick-filter-section__button"
                :key="`filter-${idx}`"
                type="ghost"
                shape="pill"
                size="l"
                routerLink
                data-click-type="Dashboard: Educator"
                v-bind="filter"
            />
        </div>
    </PageSection>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';
import PageSection from '@/components/shared/layout/PageSection.vue';

// import quickFiltersMap from '@/data/quickFiltersMap';
import { tagOptions, languageOptions } from '@/data/formData';
import quickFiltersMap from '@/data/quickFiltersMap';
import subjectIconMap from '@/data/subjectIconMap';

export default {
    name: 'QuickFiltersSection',
    components: {
        NebulaButton,
        PageSection,
    },
    computed: {
        languageOptions,
        tagOptions,
    },
    data() {
        return {
            filters: [],
        };
    },
    mounted() {
        // @todo: when the API can return a list of "valid" filters, replace 'validFilters' this with that response
        // right now it's just all tags and languages
        const tags = this.tagOptions;
        const languages = this.languageOptions;

        // const valid = [];

        // all filters that have results
        // replace this with API response
        const validFilters = {
            tag: tags.map((each) => each.value),
            language: languages.map((each) => each.value),
        };

        quickFiltersMap.forEach((quickFilter) => {
            const paramsRaw = [];
            const { filters } = quickFilter;

            let { text, icon } = quickFilter;

            // bail if no filters
            if (!filters) {
                return;
            }

            const filterTypes = Object.keys(filters);

            // if none/neither of the filter types have any results, don't include in filter list
            const isValid = !!filterTypes.find((type) => {
                const relevantFilters = filters[type];
                return !!validFilters[type].find((each) => relevantFilters.includes(each));
            });

            if (!isValid) {
                return;
            }

            // if text has not been defined in the quick filter list, pull the display text from the first (only) filter
            if (!text) {
                if (filterTypes.includes('tag')) {
                    const match = tags.find((each) => each.value === filters.tag[0]);
                    text = match.text;
                } else if (filterTypes.includes('language')) {
                    const match = languages.find((each) => each.value === filters.language[0]);
                    text = match.text;
                }
            }

            // if icon has not been defined, pull the icon from the tag
            if (!icon && filterTypes.includes('language')) {
                icon = 'chat-inactive';
            }

            if (!icon && filterTypes.includes('tag')) {
                icon = subjectIconMap[filters.tag[0]];
            }

            // check each type in the current grouping to make sure at least one filter is present in the list of valid filters
            // this ensures we don't include any buttons that will link to zero results
            filterTypes.forEach((type) => {
                const value = filters[type].join(',');
                paramsRaw.push([type, value]);
            });

            // process params into valid url param snippet
            const paramString = new URLSearchParams(paramsRaw).toString();
            const link = `/connectors?${paramString}`;
            this.filters.push({
                text,
                link,
                'icon-left': icon || 'stem-connect',
                'data-click-name': `Quick Filter: ${text}`,
            });
        });
    },
};
</script>

<style lang="stylus">
.quick-filter-section {
    &__button {
        background-color: $nebula-color-platform-interactive-100;
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        gap: $nebula-space-1x;
        margin-block-start: $nebula-space-2x;
    }
}
</style>

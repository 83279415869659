// text attribute only needed if combining more than one filter, or if the display text needs to be different from the filter display
const quickFiltersMap = [
    {
        filters: {
            tag: ['general'],
        },
        icon: 'short-answer',
    },
    {
        filters: {
            tag: ['life-science', 'physical-science', 'earth-science', 'engineering', 'mathematics', 'data', 'computer-science'],
        },
        icon: 'stem-connect',
        text: 'STEM',
    },
    {
        filters: {
            tag: ['life-science', 'physical-science', 'earth-science'],
        },
        text: 'Science',
    },
    {
        filters: {
            tag: ['engineering'],
        },
        icon: 'stem-connect',
    },
    {
        filters: {
            tag: ['health'],
        },
    },
    {
        filters: {
            tag: ['business', 'financial'],
        },
    },
    {
        filters: {
            language: ['spanish'],
        },
        text: 'Spanish Speakers',
    },
    {
        filters: {
            tag: ['social-studies'],
        },
    },
    {
        filters: {
            tag: ['english'],
        },
    },
    {
        filters: {
            tag: ['visual-arts'],
        },
    },
    {
        filters: {
            tag: ['computer-science'],
        },
    },
];

export default quickFiltersMap;

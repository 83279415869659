<template>
    <PageWrapper :contained="false">
        <template v-slot:banner>
            <NebulaBlockMessage
                v-if="showBanner"
                class="metrics-block-message"
                :description="bannerData.description"
                :header="bannerData.header"
                :icon="bannerData.icon"
                dismissible
                contained
                @closeBlockMessage="dismissBanner"
                :customStyles="{
                    blockMessageBackgroundColor: 'var(--nebula-color-platform-interface-200)',
                    blockMessageBorder: '1px solid var(--nebula-color-platform-interface-500)',
                    blockMessageHeaderColor: 'var(--nebula-color-platform-neutral-900)',
                    blockMessageHeaderFontSize: 'var(--nebula-font-size-card-title)',
                    blockMessageIconColor: 'var(--nebula-color-platform-interface-800)',
                    blockMessagePadding: 'var(--nebula-space-2x)',
                    blockMessageTextFontSize: 'var(--nebula-font-size-body-2)',
                    blockMessageTextLineHeight: '1',
                }"
            >
                <template #action>
                    <NebulaButton
                        class="metrics-block-message__button"
                        icon-left="users"
                        text="Manage Users"
                        routerLink
                        link="/user-management"
                    />
                </template>
            </NebulaBlockMessage>
        </template>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="12"
                class="organization-dashboard__summary"
            >
                <CompanyProfile
                    :data="companyData"
                />
            </NebulaGridBlock>
        </NebulaGridRow>

        <NebulaGridRow>
            <NebulaGridBlock
                :columns="12"
            >
                <PageSection
                    class="page-section--stat-cards"
                >
                    <StatCards />
                </PageSection>
            </NebulaGridBlock>
        </NebulaGridRow>

        <PageSection v-if="loading.connects">
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <GrayBoxLoader card />
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>
        <PageSection
            title="Our Connects"
            v-else
            :viewAll="{ show: true, link: '/connect-directory' }"
        >
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <RequestDirectoryTable
                        v-if="connects && connects.length > 0"
                        class="request-directory__list-view"
                        :data="connects"
                        mode="dashboard"
                    />
                    <EmptyState
                        v-else
                        card
                        muted
                        :button="{
                            text: $t('dashboard-connects-empty-button', { ns: 'orgadmin' }),
                            link: '/connect-directory',
                            iconLeft: 'clipboard',
                        }"
                        :showButton="true"
                        icon="clipboard"
                        :title="$t('dashboard-connects-empty-header', { ns: 'orgadmin' })"
                        :description="$t('dashboard-connects-empty-description', { ns: 'orgadmin' })"
                    />
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>

        <PageSection
            class="page-section--profile-cards"
            :type="hasProfileData ? 'flat' : 'content-raised'"
            title="Our Connectors"
            :viewAll="{ show: true, link: orgMembersLink }"
        >
            <CardBlock
                v-if="hasProfileData"
                :cardList="profileCardsData"
                cardType="profile"
            />
            <EmptyState
                v-else
                buttonIcon="user"
                buttonLink="/user-management"
                buttonText="Manage Users"
                icon="users"
                title="Looks like we don’t have users added for your organization."
                description="Active Connectors will appear here."
            />
        </PageSection>
        <OrgResourcesRow title="Our Content" v-if="organizationId" :category="organizationId" />
        <NebulaGridRow v-if="helpAndSupportFF">
            <NebulaGridBlock
                :columns="12"
            >
                <PageSection
                    class="page-section--resource-cards"
                    :type="'content-raised'"
                >
                    <template #sectionTitle>
                        <NebulaTitleBlock
                            class="careers-page-section__title"
                            title="Related Resources"
                            type="section"
                        />
                    </template>
                </PageSection>
            </NebulaGridBlock>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaBlockMessage, NebulaButton, NebulaGrid, NebulaTitleBlock,
} from '@discoveryedu/nebula-components';
import { setCookie, getCookie } from '@/utils/cookies';
import CardBlock from '@/components/shared/cards/CardBlock.vue';
import CompanyProfile from '@/components/dashboard/CompanyProfile.vue';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import RequestDirectoryTable from '@/components/shared/RequestDirectoryTable.vue';
import StatCards from '@/components/shared/cards/StatCards.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import { resourceCardsData } from '@/data/resourceCards';
import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';
import { scheduled, completed, canceled } from '@/constants';
import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';

import {
    // inXdays,
    timestampNow,
} from '@/data/placeholder/date';

export default {
    name: 'EmployerDashboard',
    components: {
        NebulaBlockMessage,
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTitleBlock,
        CardBlock,
        CompanyProfile,
        EmptyState,
        PageSection,
        RequestDirectoryTable,
        StatCards,
        OrgResourcesRow,
        GrayBoxLoader,
        PageWrapper,
    },
    mixins: [createPayload, scrollToTop],
    data() {
        return {
            showBanner: false,
            loading: {
                connects: true,
            },
            bannerData: {
                header: 'Welcome to your Career Connect dashboard!',
                icon: 'assignment',
                // eslint-disable-next-line
                description: 'This is where you will see company metrics related to how Connectors are engaging on the platform and their impact on classrooms.',
            },
            resourceCardsData,
            statCards: [],
            recommended: [],
        };
    },
    computed: {
        earlyAccess() {
            return this.$store.getters.earlyAccess;
        },
        helpAndSupportFF() {
            return this.$store.getters.helpAndSupportFF;
        },
        organizationId() {
            return this.$store.state.app.organization.organizationId;
        },
        orgMembersLink() {
            return `${this.$store.state.app.organization.organizationId}/connectors`;
        },
        hasProfileData() {
            return Boolean(this.$store.state.app.employees && this.$store.state.app.employees.length > 0);
        },
        profileCardsData() {
            return this.$store.state.app.employees.filter((each) => each.isActive).slice(0, 4);
        },
        companyData() {
            const { organization } = this.$store.state.app;
            return organization;
        },
        connects() {
            return this.$store.state.page.requests.filter((each) => each.type);
        },
    },
    methods: {
        dismissBanner() {
            this.showBanner = false;
            // hide the banner and set a cookie to hide it for 60 days
            setCookie('organization.banner.dismissed', 'true', 60);
        },
        async fetchConnects() {
            this.loading.connects = true;
            const requestStartDate = [];
            const requestEndDate = [timestampNow()];
            const adminOrgs = this.$store.getters.userOrganizations;
            const rawConnectParams = [
                ['limit', 5],
                ['status', scheduled],
                ['status', completed],
                ['status', canceled],
                ['startDate', requestStartDate],
                ['endDate', requestEndDate],
                ['order', 'DESC'],
            ];

            adminOrgs.forEach((org) => rawConnectParams.push(['organizations', org.organizationId]));

            const connectParams = new URLSearchParams(rawConnectParams).toString();
            const connectPayload = await this.createPayload({ params: connectParams });
            await this.$store.dispatch('getRequests', connectPayload);
            this.loading.connects = false;
        },
    },
    async mounted() {
        this.scrollToTop();
        const dismissed = getCookie('organization.banner.dismissed');
        if (!dismissed) {
            // if banner has not been dismissed, show it
            this.showBanner = true;
        }

        const payload = await this.createPayload();
        await this.$store.dispatch('getOrganizationEmployees', payload);

        Promise.all([
            this.fetchConnects(),
        ]);
    },
    provide: {
        domainType: 'employer',
        pageType: 'dashboard',
    },
};
</script>

<style lang="stylus">
.metrics-block-message {
    .nebula-block-message__action-container {
        justify-content: flex-end;
    }
}

.organization-dashboard {
    &__summary {
        card-base();
    }
}
</style>

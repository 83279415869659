<template>
    <div :class="{ 'de-logo': true, 'de-logo--badge': badge, 'de-logo--large-badge': large && badge }">
        <img :class="{
            'de-logo__img--icon': badge,
            'de-logo__img--wordmark': !badge,
        }"
        :src="imgSrc"
    >
    </div>
</template>

<script>
const deLogo = require('@/assets/de-logo_md.png');
const deLogoIcon = require('@/assets/de-logo.png');

export default {
    name: 'DELogoBadge',
    props: {
        badge: Boolean,
        large: Boolean,
    },
    computed: {
        imgSrc() {
            return this.badge ? deLogoIcon : deLogo;
        },
    },
};
</script>

<style lang="stylus">
.de-logo {
    display: flex;
    align-items: center;
    &--badge {
        background-color: $nebula-color-white;
        border-radius: 50%;
        box-shadow: 0px 2.56px 9.6px 1.28px #0000001A;
        height: 24px;
        justify-content: center;
        width: 24px;
    }
    &--large-badge {
        height: 36px;
        width: 36px;
    }
    &__img {
        &--icon {
            width: $nebula-space-2x;
        }
        &--wordmark {
            height: $nebula-space-2x;
        }
    }
}
</style>

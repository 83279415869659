<template>
    <div :class="spotlightRowClass">
            <div v-for="(item, index) in items" :key="index" class="spotlight-row__item">
              <p class="spotlight-row__type-description spotlight-row__type-description--multiple">CAREER CONNECT SPOTLIGHT</p>
              <div class="spotlight-row__column spotlight-row__column--text">
                  <p class="spotlight-row__type-description">CAREER CONNECT SPOTLIGHT</p>
                  <p class="spotlight-row__title">{{ item.title }}</p>
                  <p v-if="item.featuring" class="spotlight-row__featuring">Featuring<br>{{ item.featuring }}</p>
                  <p class="spotlight-row__description" v-html="item.description" />
                  <NebulaButton
                    class="spotlight-row__button nebula-button--ghost"
                    :text="item.buttonText"
                    :link="item.buttonLink"
                    :routerLink="false"
                    :data-click-type="item['data-click-type']"
                    :data-click-name="item['data-click-name']"
                  />
              </div>
              <div class="spotlight-row__column spotlight-row__column--image">
                  <img :src="item.imageSrc" alt="Spotlight Image" class="spotlight-row__image">
              </div>
        </div>
    </div>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';

export default {
    name: 'SpotlightRow',
    components: { NebulaButton },
    props: {
        items: {
            type: Array,
            required: true,
            validator(value) {
                return value.every((item) => typeof item.title === 'string'
                  && typeof item.description === 'string'
                  && (item.featuring === undefined || typeof item.featuring === 'string')
                  && typeof item.buttonText === 'string'
                  && typeof item.buttonLink === 'string'
                  && typeof item['data-click-type'] === 'string'
                  && typeof item['data-click-name'] === 'string'
                  && typeof item.imageSrc === 'string');
            },
        },
    },
    computed: {
        spotlightRowClass() {
            return {
                'spotlight-row': true,
                'spotlight-row--multiple': this.items.length > 1,
            };
        },
    },
};
</script>

<style lang="stylus">
.spotlight-row {
  p {
    margin: 10px 0;
  }
  &__item {
    display: flex;
    gap: $nebula-space-6x;
    justify-content: space-between;
    align-items: center;
    background-color: #DBF0FF;
    padding: 32px clamp(0.5rem, -0.1446rem + 3.6832vw, 3rem);
    @media (max-width: $nebula-breakpoints-tablet-portrait) {
      flex-direction: column;
    }
  }

  &__column {
    &--text {
      flex: 1;
      padding: 1rem 0;
    }

    &--image {
      flex: 0 0 30%;
    }
  }

  &__type-description {
    color: $nebula-color-platform-interactive-900;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    &--multiple {
      display: none;
    }
  }

  &__title {
    color: $nebula-color-platform-neutral-900;
    font-size: 43px;
    font-weight: 700;
    line-height: 120%;
  }

  &__description {
    font-size: 16px;
    line-height: 130%;
  }

  &__featuring {
    color: $nebula-color-platform-neutral-800;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }

  &__image {
    max-width: 100%;
    height: auto;
    max-height: 400px;
  }

  &__button {
    display: flex;
    justify-content: center;
    @media (max-width: $nebula-breakpoints-tablet-portrait - 1) {
        width: 100%;
    }
  }
}
.spotlight-row--multiple {
  display: flex;
  gap: $nebula-space-3x;

  .spotlight-row {
    &__item {
      border-radius: $nebula-space-2x;
      flex-direction: column;
      gap: 0;
    }
    &__column--image {
      order: 1
      width: 100%;
    }
    &__column--text {
      order: 2
      padding: 0;
    }
    &__image {
      max-height: 100%;
      width: 100%;
    }
    &__featuring {
      display: none;
    }
    &__type-description {
      display: none;
      &--multiple {
        display: block;
        align-self: flex-start;
      }
    }
  }
}
</style>

<template>
    <div class="de-profile-avatar">
        <div class="de-profile-avatar__avatar-container">
            <ProfileAvatar
                class="de-profile-avatar__user-avatar"
                :image="image"
                :name="name"
                :size="size"
            />
            <DELogoBadge class="de-profile-avatar__logo-badge" badge :large="size === 'l'" />
        </div>
    </div>
</template>

<script>
import DELogoBadge from '@/components/shared/tokens/DELogoBadge.vue';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

export default {
    name: 'DEProfileAvatar',
    components: {
        DELogoBadge,
        ProfileAvatar,
    },
    props: {
        name: String,
        image: String,
        size: {
            type: String,
            default: 'm',
        },
    },
};
</script>

<style lang='stylus'>
.de-profile-avatar {
    display: flex;

    &__avatar-container {
        position: relative;
    }
    &__logo-badge {
        position: absolute;
        right: "-%s" % $nebula-space-1x;
        bottom: "-%s" % $nebula-space-half;
    }
    &__user-avatar {
        --careers-avatar-border-color: var(--nebula-color-white);
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWrapper',{attrs:{"contained":false},scopedSlots:_vm._u([{key:"banner",fn:function(){return [(_vm.showBanner)?_c('NebulaBlockMessage',{staticClass:"metrics-block-message",attrs:{"description":_vm.bannerData.description,"header":_vm.bannerData.header,"icon":_vm.bannerData.icon,"dismissible":"","contained":"","customStyles":{
                blockMessageBackgroundColor: 'var(--nebula-color-platform-interface-200)',
                blockMessageBorder: '1px solid var(--nebula-color-platform-interface-500)',
                blockMessageHeaderColor: 'var(--nebula-color-platform-neutral-900)',
                blockMessageHeaderFontSize: 'var(--nebula-font-size-card-title)',
                blockMessageIconColor: 'var(--nebula-color-platform-interface-800)',
                blockMessagePadding: 'var(--nebula-space-2x)',
                blockMessageTextFontSize: 'var(--nebula-font-size-body-2)',
                blockMessageTextLineHeight: '1',
            }},on:{"closeBlockMessage":_vm.dismissBanner},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('NebulaButton',{staticClass:"metrics-block-message__button",attrs:{"icon-left":"users","text":"Manage Users","routerLink":"","link":"/user-management"}})]},proxy:true}],null,false,2980181506)}):_vm._e()]},proxy:true}])},[_c('NebulaGridRow',[_c('NebulaGridBlock',{staticClass:"organization-dashboard__summary",attrs:{"columns":12}},[_c('CompanyProfile',{attrs:{"data":_vm.companyData}})],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":12}},[_c('PageSection',{staticClass:"page-section--stat-cards"},[_c('StatCards')],1)],1)],1),(_vm.loading.connects)?_c('PageSection',[_c('NebulaGridRow',{attrs:{"type":"flex"}},[_c('NebulaColumn',{attrs:{"columns":12}},[_c('GrayBoxLoader',{attrs:{"card":""}})],1)],1)],1):_c('PageSection',{attrs:{"title":"Our Connects","viewAll":{ show: true, link: '/connect-directory' }}},[_c('NebulaGridRow',{attrs:{"type":"flex"}},[_c('NebulaColumn',{attrs:{"columns":12}},[(_vm.connects && _vm.connects.length > 0)?_c('RequestDirectoryTable',{staticClass:"request-directory__list-view",attrs:{"data":_vm.connects,"mode":"dashboard"}}):_c('EmptyState',{attrs:{"card":"","muted":"","button":{
                        text: _vm.$t('dashboard-connects-empty-button', { ns: 'orgadmin' }),
                        link: '/connect-directory',
                        iconLeft: 'clipboard',
                    },"showButton":true,"icon":"clipboard","title":_vm.$t('dashboard-connects-empty-header', { ns: 'orgadmin' }),"description":_vm.$t('dashboard-connects-empty-description', { ns: 'orgadmin' })}})],1)],1)],1),_c('PageSection',{staticClass:"page-section--profile-cards",attrs:{"type":_vm.hasProfileData ? 'flat' : 'content-raised',"title":"Our Connectors","viewAll":{ show: true, link: _vm.orgMembersLink }}},[(_vm.hasProfileData)?_c('CardBlock',{attrs:{"cardList":_vm.profileCardsData,"cardType":"profile"}}):_c('EmptyState',{attrs:{"buttonIcon":"user","buttonLink":"/user-management","buttonText":"Manage Users","icon":"users","title":"Looks like we don’t have users added for your organization.","description":"Active Connectors will appear here."}})],1),(_vm.organizationId)?_c('OrgResourcesRow',{attrs:{"title":"Our Content","category":_vm.organizationId}}):_vm._e(),(_vm.helpAndSupportFF)?_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":12}},[_c('PageSection',{staticClass:"page-section--resource-cards",attrs:{"type":'content-raised'},scopedSlots:_vm._u([{key:"sectionTitle",fn:function(){return [_c('NebulaTitleBlock',{staticClass:"careers-page-section__title",attrs:{"title":"Related Resources","type":"section"}})]},proxy:true}],null,false,1646969519)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <GrayBoxLoader v-if="loading" />
    <div v-else class="profile-summary">
        <div class="profile-summary__content">
            <DEProfileAvatar
                v-if="selfView && isEducator"
                :image="data.avatarUrl"
                :name="data.firstName + ' ' + data.lastName"
            />
            <ProfileAvatar
                v-else
                class="profile-summary__user-avatar"
                :image="data.avatarUrl"
                :name="data.firstName + ' ' + getLastNameInitials(data.lastName)"
                size="m"
            />
            <div class="profile-summary__user-information">
                <h2 class="profile-summary__name">
                    {{ displayName }} {{ (selfView && isEducator) ? data.lastName : getLastNameInitials(data.lastName) }}
                </h2>
                <p v-if="data.title" class="profile-summary__title">
                    {{ data.title }}
                </p>
            </div>
            <div class="profile-summary__org-list">
                <div
                    v-for="(organization, idx) in organizations"
                    :key="idx"
                    class="profile-summary__org"
                >
                    <ProfileAvatar
                        v-if="organization.avatarUrl"
                        class="profile-summary__org-avatar"
                        :image="organization.avatarUrl"
                        :border="true"
                        icon="buildings"
                        :name="organization.name"
                        size="xs"
                    />

                    <p v-if="preview" class="profile-summary__org-link">
                        {{ organization.name }}
                    </p>
                    <OrgName v-else class="profile-summary__org-name" :isLink="!organization.isSite" :organization="organization" />
                </div>
            </div>
            <div class="profile-summary__location-group">
                <NebulaIcon
                    class="profile-summary__location-icon"
                    symbol-id="pin"
                    size="s"
                />
                <p
                    class="profile-summary__location"
                    :class="{ 'profile-summary__location--spaced': isEducator }"
                >
                    {{ location }}
                </p>
            </div>
            <NebulaButton
                v-if="showDirectRequest"
                class="profile-summary__direct-request-button"
                text="Send Direct Request"
                type="ghost"
                routerLink
                :link="`/direct-request/${data.employeeId}`"
                data-click-type="View profile Profile: Educator"
                data-click-name="Send Direct Request"
            />
            <section v-if="data.description" class="profile-summary__section">
                <p class="profile-summary__description">
                    {{ description }}
                </p>
            </section>
            <section v-if="hasTags" class="profile-summary__section profile-summary__tags">
                <ul class="profile-summary__list">
                    <li
                        v-for="tag in tags"
                        :key="tag"
                        class="profile-summary__list-item"
                    >
                        <NebulaBadge
                            :text="getTagText(tag)"
                            :icon="getSubjectIcon(tag)"
                            size="s"
                            :customStyles="{
                                badgeBackgroundColor: 'var(--nebula-color-platform-interface-300)',
                                badgeColor: 'var(--nebula-color-platform-interface-1000)',
                            }"
                        />
                    </li>
                </ul>
            </section>
            <section class="profile-summary__section profile-summary__section--associations" v-if="data.associations">
                <h3 class="employee-section__title">Professional Associations</h3>
                <ul class="profile-summary__list">
                    <li
                        v-for="association in data.associations"
                        :key="association"
                        class="employee-section__list-item"
                    >
                        <a :href="association.link" class="employee-section__link">
                            {{ association.text }}
                        </a>
                    </li>
                </ul>
            </section>
            <div v-if="!preview && selfView" class="profile-summary__edit-button-group">
                <DELogoBadge v-if="isEducator" />
                <NebulaButton
                    class="profile-summary__edit-button"
                    v-bind="editProfileAttrs"
                    text="Edit Profile"
                    type="flat"
                    size="s"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { NebulaBadge, NebulaButton, NebulaIcon } from '@discoveryedu/nebula-components';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import OrgName from '@/components/shared/tokens/OrgName.vue';
import DELogoBadge from '@/components/shared/tokens/DELogoBadge.vue';
import DEProfileAvatar from '@/components/shared/Profile/DEProfileAvatar.vue';
import subjectIconMap from '@/data/subjectIconMap';
import { tagOptions, stateOptions } from '@/data/formData';
import { roles } from '@/constants';
import { getLastNameInitials } from '@/utils/data/display';

const envMap = {
    development: '.dev',
    qa: '.qa',
    staging: '.stage',
    production: '',
};

export default {
    name: 'ProfileCard',
    components: {
        NebulaBadge,
        NebulaButton,
        NebulaIcon,
        ProfileAvatar,
        GrayBoxLoader,
        OrgName,
        DELogoBadge,
        DEProfileAvatar,
    },
    props: {
        data: {
            type: Object,
        },
        preview: {
            type: Boolean,
        },
        selfView: {
            type: Boolean,
            default: false,
        },
        loading: Boolean,
    },
    data() {
        return {
            subjectIconMap,
        };
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        isEducator() {
            return this.appMode.toLowerCase() === roles.EDUCATOR_ROLE.toLowerCase();
        },
        editProfileAttrs() {
            if (this.appMode === roles.EDUCATOR_ROLE) {
                const env = process.env.NODE_ENV;
                const envString = envMap[env.toLowerCase()];
                return {
                    link: `https://app${envString}.discoveryeducation.com/learn/profile/personalization`,
                    target: '_blank',
                    'icon-right': 'new-window',
                    'aria-label': 'Edit Profile, opens in Discovery Education',
                };
            }

            return {
                link: '/profile-setup',
                'icon-left': 'edit',
            };
        },
        location() {
            // DE accounts will store locations under 'site_x' keys
            let { city, state } = this.data;

            if (!city && !state) {
                const { site: { site_city: siteCity, site_state: siteState } } = this.data;
                const stateDisplay = stateOptions.find((each) => each.fullname === siteState).value || siteState;

                city = siteCity;
                state = stateDisplay;
            }

            city = city ? `${city}, ` : '';

            return `${city}${state}`;
        },
        hasTags() {
            try {
                Object.keys(this.data.tags);
                return this.data.tags.length > 0;
            } catch {
                return false;
            }
        },
        displayName() {
            return this.data.preferredName || this.data.firstName;
        },
        tagOptions,
        organizations() {
            if (this.selfView && this.appMode === roles.EDUCATOR_ROLE) {
                const { site: { site_name: siteName } } = this.$store.state.user.data;
                return [
                    {
                        name: siteName,
                        isSite: true,
                    },
                ];
            }
            return this.selfView ? this.$store.state.user.organizations : this.data.organizations;
        },
        showDirectRequest() {
            return this.appMode === roles.EDUCATOR_ROLE && !this.selfView;
        },
        description() {
            const baseDescription = this.data.description;
            if (baseDescription?.length > 255) {
                return `${baseDescription.substring(0, 110)}...`;
            }

            return baseDescription;
        },
        tags() {
            let { tags } = this.data;
            if (tags?.length > 2) {
                const numberToDisplay = 2;
                const remainder = tags.length - numberToDisplay;
                tags = tags.slice(0, numberToDisplay);
                tags.push(`+${remainder}`);
            }
            return tags;
        },
    },
    methods: {
        getLastNameInitials,
        getSubjectIcon(subject) {
            const subjectString = subject.replace(/[- ]/g, '').trim().toLowerCase();

            return this.subjectIconMap[subjectString];
        },
        getTagText(value) {
            const match = this.tagOptions.find((each) => each.value === value);
            if (match) {
                return match.text;
            }
            return value;
        },
        getOrgId(org) {
            return org.organizationId || org.organization_id;
        },
    },
};
</script>

<style lang='stylus'>
.profile-summary {
    height: 100%;
    position: relative;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        min-height: 290px;
    }

    &__content {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: $nebula-space-half;
        height: 100%;
        padding: 0 $nebula-space-3x $nebula-space-3x;
        padding-block-start: $nebula-space-2x;
    }

    &__direct-request-button {
        --nebula-button-background: $nebula-color-white;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            position: absolute;
            top: $nebula-space-2x;
            right: $nebula-space-2x;
        }
    }

    &__language-badge {
        --nebula-badge-text-transform: capitalize;
    }

    &__logo-badge {
        position: absolute;
        right: "-%s" % $nebula-space-1x;
        bottom: "-%s" % $nebula-space-half;
    }

    &__avatar-container {
        position: relative;
    }

    &__user-avatar {
        --careers-avatar-border-color: var(--nebula-color-white);
    }

    &__edit-button-group {
        display: flex;
    }

    &__tags {
        margin-block-start: $nebula-space-2x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-block-start: $nebula-space-half;
        }
    }

    &__description {
        color: $nebula-color-platform-neutral-800;
        text-align: center;
        font-size: $nebula-font-size-caption;
        line-height: 1.2;
        margin: 0;
    }

    &__name {
        nebula-text-body-1();
        color: $nebula-color-platform-neutral-900;
        font-weight: 600;
        text-align: center;
        margin: 0;
    }

    &__pronouns {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
    }

    &__title {
        nebula-text-body-2();

        color: $nebula-color-platform-neutral-800;
        font-weight: 600;
        text-align: center;
        margin: 0;
        margin-block-end: $nebula-space-half;
    }

    &__location-group {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-block-end: $nebula-space-quarter;
        &--spaced {
            margin-block-start: $nebula-space-1x;
        }
    }

    &__location {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
        text-align: center;
        margin: 0;
    }

    &__location-icon {
        margin-inline-end: $nebula-space-half;
    }

    &__org-list {
        display: flex;
        flex-direction: column;
        margin-block-end: $nebula-space-quarter;
    }

    &__org {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x;
    }

    &__org-avatar {
        border: 1px solid $nebula-color-platform-neutral-200;
    }

    &__org-link {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
    }

    &__org-name {
        nebula-text-semibold();
        text-align: center;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
        width: 100%;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: $nebula-space-1x;
        margin: 0;
        padding: 0;

        .profile-summary__section--associations & {
            flex-direction: column;
        }
    }

    &__list-item {
        list-style: none;
    }
}

.employee-section__title {
    nebula-text-body-1();

    font-weight: bold;
    margin: 0;
}

.employee-section__text {
    nebula-text-body-2();

    margin: 0;
}

.employee-section__link {
    color: $nebula-color-platform-neutral-900;
}
</style>

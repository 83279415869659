import { ATLAS_APP_BASE_URL } from '@/constants';

const spotlights = [
    {
        title: 'Polar Bears International',
        description: `Are your kiddos fascinated by Polar Bears? Do they have a passion for wildlife
        conservation or biology? Join us to learn how a variety of talents and expertise are needed
        to keep Polar Bears healthy and safe. Adorable polar bear sightings included!`,
        imageSrc: 'https://d1m0scxbrw6oep.cloudfront.net/thumbnails/a35802cd-da43-437a-9a83-ade05f83802a-medium',
        buttonText: 'Watch Recording Now',
        buttonLink: `${ATLAS_APP_BASE_URL}/pages/5a4aefe1-d4d4-472c-a8de-83e69fe9dfa8`,
        'data-click-type': 'Dashboard: Educator',
        'data-click-name': 'Watch Spotlight Recording',
    },
];

export default spotlights;

<template>
    <PageWrapper
        class="employee-dashboard-container"
        :contained="false"
    >
        <NebulaGridRow v-if="pageHeader && flags['intro-steps']" gutter>
            <Intro>
                <ProfileCard
                    v-if="flags['intro-steps']"
                    :data="user"
                    selfView
                />
            </Intro>
        </NebulaGridRow>
        <NebulaGridRow v-else-if="pageHeader" type="flex">
            <NebulaColumn :columns="12">
                <PageHeader :title="pageHeader">
                    <template v-slot:actions>
                        <NebulaButton
                            icon-left="clipboard"
                            type="ghost"
                            text="Create a New Request"
                            routerLink
                            link="/request/new"
                            data-click-type="Dashboard: Educator"
                            data-click-name="Create a New Request"
                        />
                    </template>
                </PageHeader>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="!flags['intro-steps']" gutter>
            <NebulaGridBlock
                class="educator-dashboard__card"
                :columns="4"
                :mdSize="8"
            >
                <ProfilePanel :data="user" />
            </NebulaGridBlock>
            <NebulaGridBlock
                class="educator-dashboard__connect"
                :columns="8"
                :mdSize="8"
            >
                <UpcomingRequest />
            </NebulaGridBlock>
        </NebulaGridRow>
        <PageSection
            v-if="flags['intro-steps'] && flags['educator-upcoming-request'] && showUpcomingRequest"
            type="emphasized"
            padded
        >
            <NebulaGridRow>
                <NebulaGridBlock
                    :columns="12"
                >
                    <UpcomingRequest @hide-section="hideUpcomingRequest" />
                </NebulaGridBlock>
            </NebulaGridRow>
        </PageSection>
        <PageSection
            title="My Connects"
            v-if="loading.requests || connects.length > 0"
            :viewAll="{ show: true, link: '/connect-directory' }"
        >
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <GrayBoxLoader v-if="loading.requests" card />
                    <RequestDirectoryTable
                        v-else
                        class="request-directory__list-view"
                        :data="connects"
                        mode="dashboard"
                    />
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>
        <QuickFiltersSection v-if="flags['quick-filters']" />
        <PageSection
            v-if="pageLoading || recommendedConnectors && recommendedConnectors.length > 1"
            title="Recommended For You"
            description="Explore the guest speakers interested in sharing their expertise with your classroom. View their profile to learn more."
            :viewAll="{ show: true, link: '/connectors' }"
        >
            <GrayBoxLoader v-if="pageLoading" cardRow />
            <NebulaGridRow v-else gutter>
                <NebulaGridBlock
                    :columns="12"
                    :mdSize="8"
                >
                    <CardBlock
                        :cardList="recommendedConnectors"
                        :cardCount="4"
                        cardType="profile"
                    />
                </NebulaGridBlock>
            </NebulaGridRow>
        </PageSection>
        <ResourcesRow
            title="My Resources"
            description="Explore the guest speakers interested in sharing their expertise with your classroom! View their profile to learn more."
            topic="evergreen-educator"
        />
        <ResourcesRow
            title="Recommended Content"
            description="Explore career-related content to use in your classroom."
            topic="recommended"
            :max="8"
            :viewAll="{ show: true, link: '/browse', text: 'Browse all content' }"
        />
    </PageWrapper>
</template>

<script>
import { NebulaGrid, NebulaButton } from '@discoveryedu/nebula-components';
import PageSection from '@/components/shared/layout/PageSection.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import Intro from '@/components/dashboard/Intro.vue';
import ProfilePanel from '@/components/dashboard/ProfilePanel.vue';
import UpcomingRequest from '@/components/dashboard/UpcomingRequest.vue';
import CardBlock from '@/components/shared/cards/CardBlock.vue';
import createPayload from '@/mixins/data/createPayload';
import ResourcesRow from '@/components/resources/ResourcesRow.vue';
import RequestDirectoryTable from '@/components/shared/RequestDirectoryTable.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import ProfileCard from '@/components/dashboard/ProfileCard.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import QuickFiltersSection from '@/components/dashboard/Educator/QuickFiltersSection.vue';

import {
    timestampNow,
} from '@/data/placeholder/date';
import { futureStatuses } from '@/constants';

export default {
    name: 'EducatorDashboard',
    components: {
        CardBlock,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaButton,
        PageSection,
        PageWrapper,
        ProfilePanel,
        PageHeader,
        UpcomingRequest,
        ResourcesRow,
        RequestDirectoryTable,
        GrayBoxLoader,
        Intro,
        ProfileCard,
        QuickFiltersSection,
    },
    mixins: [createPayload],
    computed: {
        user() {
            return this.$store.state.user.data;
        },
        displayName() {
            return this.user.preferredName || this.user.firstName;
        },
        pageHeader() {
            return `Welcome, ${this.displayName}!`;
        },
        recommendedConnectors() {
            return this.$store.state.page.employees;
        },
        pageLoading() {
            return this.$store.state.status.page.loading;
        },
        connects() {
            return this.$store.state.page.requests;
        },
        flags() {
            return this.$store.getters.flags;
        },
    },
    data() {
        return {
            loading: {
                requests: true,
            },
            showUpcomingRequest: true,
        };
    },
    async mounted() {
        // const { isActive, id } = this.user;

        // TODO: fetch request and seed fetch with that if present
        const rawParams = [['limit', 4], ['sort', 'random']];
        const params = new URLSearchParams(rawParams).toString();

        const rawConnectParams = [['limit', 5], ['startDate', timestampNow()]];
        futureStatuses.forEach((status) => rawConnectParams.push(['status', status]));
        const connectParams = new URLSearchParams(rawConnectParams).toString();

        const connectPayload = await this.createPayload({ params: connectParams });
        await this.$store.dispatch('getRequests', connectPayload);
        this.loading.requests = false;

        const payload = await this.createPayload({ params });
        await this.$store.dispatch('getEmployees', payload);

        // if (id && !isActive) {
        //     this.$router.push('/profile-setup');
        // }
    },
    methods: {
        hideUpcomingRequest() {
            this.showUpcomingRequest = false;
        },
    },
    async beforeDestroy() {
        await this.$store.dispatch('resetPageData');
    },
};
</script>

<style lang="stylus">
.educator-dashboard {
    &__card {
        card-base();
    }

    &__connect {
        card-base();

        background-color: $nebula-color-platform-interface-100;
        border: 1px solid $nebula-color-platform-interface-400;
        padding: $nebula-space-3x;
    }
}
</style>

<template>
    <div class="blob-background">
        <img class="blob-background__stars" :src="stars">
        <img class="blob-background__lines" :src="lines">
        <img class="blob-background__blob" :src="blob">

        <slot />
    </div>
</template>

<script>
const stars = require('@/assets/textures/stars.svg');
const lines = require('@/assets/textures/lines.svg');
const blob = require('@/assets/textures/blob.svg');

export default {
    name: 'BlobBackground',
    data() {
        return {
            stars,
            lines,
            blob,
        };
    },
};
</script>

<style lang="stylus">
blob-hide-on-mobile() {
    @media (max-width: $nebula-breakpoints-tablet-portrait - 1) {
        display: none;
    }
}
.blob-background {
    height: 100%;
    width: 100%;
    position: relative;
    &__stars {
        blob-hide-on-mobile();
        position: absolute;
        bottom: -87px;
        left: -40px;
        height: 65px;
    }
    &__lines {
        blob-hide-on-mobile();
        position: absolute;
        right: 0;
        top: 0;
        height: 72px;
        top: -42px;
        right: -50px;
    }
    &__blob {
        blob-hide-on-mobile();
        position: absolute;
        right: -12%;
        top: -15%;
        width: 135%;
        z-index: -1;
    }
}
</style>
